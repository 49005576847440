import React from 'react';
import {Dialog, ViewTable, Layout, Filter, Button} from '@/components';
import columns from './purchase_return_order_columns';
import apis from '@/api/api';
import { routerMap } from '@/router/agent_router';
import LibUtils from 'lib/utils';
import LibEnum from 'lib/enum';
import { LibAuthCode } from 'lib/Auth';
import WebApi from '@/web/api';

export default class PurchaseReturnList extends React.PureComponent<RouterPropsType> {

  private table = React.createRef<ViewTable>()
  private params = {}

  state = {
    visible: false,
  }

  render() {
    const { history } = this.props;

    return (
      <Layout.Page flexColumn overHidden>
        <Layout.Toolbar title="进货退货单">
          <Button authCode={LibAuthCode.purchaseReturnOrder.create} type="primary" onClick={() => this.props.history.push(routerMap["purchaseReturn.create"].path)}>新增进货退货单</Button>
          <Button.Refresh onClick={() => {
            this.table.current?.reset();
          }}></Button.Refresh>
        </Layout.Toolbar>
        {/* 搜索条件 */}
        <Filter
          fields={[
            {
              type: 'select',
              name: '订单状态',
              field: 'orderStatus',
              options: LibUtils.enumToOptions(LibEnum.PURCHASE_ORDER_STATUS)
            },
            {
              type: 'select',
              name: '付款状态',
              field: 'paymentStatus',
              options: LibUtils.enumToOptions(LibEnum.PAYMENT_STATUS_3)
            },
            {
              type: 'input',
              name: '搜索',
              field: 'keyword',
              placeholder: '订单号/供应商'
            }
          ]}
          onChange={values => {
            this.params = values;
            this.table.current?.reset();
          }}
        />

        {/* table 数据 */}
        <ViewTable
          flex1
          ref={this.table}
          fetch={data => {
            return WebApi.purchase_return_order_paging({...data, ...this.params})
          }}
          actionButtons={[
            {
              type: 'invalid',
              authCode: LibAuthCode.purchaseReturnOrder.update,
              disabled: row => row.orderStatus !== LibEnum.PURCHASE_RETURN_ORDER_STATUS.WAIT_AUDIT.value,
              onClick: row => {
                if (row.orderStatus !== '11') {
                  Dialog.warning({
                    title: '不可作废',
                    content: '只有待审核的订单才可以作废！'
                  });
                  return;
                }
                this.clickInvalid(row);
              }
            },
            {
              type: 'delete',
              authCode: LibAuthCode.purchaseReturnOrder.delete,
              disabled: row => row.orderStatus !== LibEnum.PURCHASE_RETURN_ORDER_STATUS.INVALID.value,
              onClick: row => {
                if (row.orderStatus !== '99') {
                  Dialog.warning({
                    title: '不可删除',
                    content: '只有作废的订单才可以删除！'
                  });
                  return;
                }
                this.clickDelete(row);
              }
            }
          ]}
          columns={columns as any}
          rowKey={row => row.purchaseReturnId}/>
      </Layout.Page>
    )
  }

  // 作废订单
  clickInvalid = record => {
    Dialog.confirm({
      title: '作废确认',
      content: '确认作废订单吗？',
      onOk: async() => {
        apis['purchase-return.cancel'].post({
          purchaseReturnId: record.id
        })
        .then(() => {
          showSuccess.save();
        })
      },
    });
  }

  // 删除订单
  clickDelete = record => {
    Dialog.confirm({
      content: `确认删除订单[${record.id}]吗？`,
      onOk: async () => {
        
        apis['purchase-return.delete'].post({
          purchaseReturnId: record.id
        })
        .then(() => {
          showSuccess.delete();
        })
      }
    });
  }
}